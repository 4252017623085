import { Middleware } from 'redux';
import { AppError } from 'src/application/entities/app-error';
import { Services } from 'src/application/interfaces';
import { Store } from 'src/application/middleware';

import { validateRequired } from 'application/utils/validators';
import {
  setFormLoading,
  setFormSuccess,
  setGeneralErrorMessage,
  SetInput,
  SetInputValidated,
  SetPhoneInput,
  SetPhoneInputValidated,
  setRequiredInput,
  setRequiredPhoneInput,
  SET_INPUT_SD_CONSUMER_CALL_BACK,
  SET_PHONE_INPUT_SD_CONSUMER_CALL_BACK,
  SubmitForm,
  SUBMIT_FORM_SD_CONSUMER_CALL_BACK,
} from './actions';

export const InputChanged = (
): Middleware => () => (next) => (action: SetInput): void => {
  if (action.type === SET_INPUT_SD_CONSUMER_CALL_BACK) {
    const { payload } = action as SetInput;

    let error = '';
    payload.validators.forEach((validate) => {
      const errorCode = validate(payload.value);
      if (errorCode) {
        error = errorCode;
      }
    });

    const validatedPayload: SetInputValidated['payload'] = {
      [payload.fieldName]: {
        value: payload.value,
        error,
      },
    };

    next({
      ...action,
      payload: validatedPayload,
    });
    return;
  }

  next(action);
};

export const PhoneInputChanged = (
): Middleware => () => (next) => (action): void => {
  if (action.type === SET_PHONE_INPUT_SD_CONSUMER_CALL_BACK) {
    const { payload } = action as SetPhoneInput;

    const validatedPayload: SetPhoneInputValidated['payload'] = {
      phoneNumber: {
        countryCode: payload.countryCode,
        number: payload.number,
        error: payload.isRequired ? validateRequired(payload.number) : '',
      },
    };

    next({
      ...action,
      payload: validatedPayload,
    });
    return;
  }

  next(action);
};

export const FormSubmitted = ({ api }: Services): Middleware => ({
  dispatch,
  getState,
}: Store) => (next) => async (action: SubmitForm): Promise<void> => {
  next(action);

  if (action.type === SUBMIT_FORM_SD_CONSUMER_CALL_BACK) {
    if (!api?.shipaDelivery?.CS.requestCallBack) {
      return;
    }

    dispatch(setFormLoading(true));

    const { sdConsumerCallback } = getState();

    if (!sdConsumerCallback) {
      throw new Error('Failed loading module');
    }

    const {
      name,
      phoneNumber,
      orderNumbers,
      reason,
      recaptcha,
    } = sdConsumerCallback;

    let canSubmit = true;

    dispatch(setGeneralErrorMessage(''));
    if (validateRequired(name.value)) {
      dispatch(setRequiredInput('name')(name.value));
      canSubmit = false;
    }
    if (validateRequired(orderNumbers.value)) {
      dispatch(setRequiredInput('orderNumbers')(orderNumbers.value));
      canSubmit = false;
    }
    if (validateRequired(phoneNumber.number)) {
      dispatch(setRequiredPhoneInput(
        phoneNumber.countryCode,
        phoneNumber.number,
      ));
      canSubmit = false;
    }
    if (validateRequired(reason.value)) {
      dispatch(setRequiredInput('reason')(reason.value));
      canSubmit = false;
    }
    if (validateRequired(recaptcha.value)) {
      dispatch(setRequiredInput('recaptcha')(recaptcha.value));
      canSubmit = false;
    }

    if (!canSubmit) {
      dispatch(setFormLoading(false));
      return;
    }

    try {
      await api.shipaDelivery.CS.requestCallBack({
        isBusiness: true,
        name: name.value,
        phone: phoneNumber.countryCode + phoneNumber.number,
        orderNumbers: orderNumbers.value,
        reason: parseInt(reason.value, 10),
        marketingCampaign: 'Shipa.com-Consumer-Call-Back',
      });
      dispatch(setFormSuccess(true));
    } catch (error) {
      if (error instanceof AppError && error.code === 'validationError') {
        dispatch(setGeneralErrorMessage(error.description));
      } else {
        dispatch(setGeneralErrorMessage(
          error.description || error.message,
        ));
      }
      // eslint-disable-next-line no-console
      console.error(error.message);
    }

    dispatch(setFormLoading(false));
  }
};

export default [
  InputChanged,
  PhoneInputChanged,
  FormSubmitted,
];
