import {
  validateRequired,
  Validator,
} from 'application/utils/validators';

// eslint-disable-next-line max-len
export const SUBMIT_FORM_SD_CONSUMER_CALL_BACK = '[form] submit form sd consumer call back';
export type SubmitForm = {
  type: typeof SUBMIT_FORM_SD_CONSUMER_CALL_BACK;
}
export const submitForm = {
  type: SUBMIT_FORM_SD_CONSUMER_CALL_BACK,
};

// eslint-disable-next-line max-len
export const SET_FORM_LOADING_SD_CONSUMER_CALL_BACK = '[forms] set loading sd consumer call back';
export type SetFormLoading = {
  type: typeof SET_FORM_LOADING_SD_CONSUMER_CALL_BACK;
  payload: {
    isLoading: boolean;
  }
};

export const setFormLoading = (
  isLoading: boolean,
): SetFormLoading => ({
  type: SET_FORM_LOADING_SD_CONSUMER_CALL_BACK,
  payload: {
    isLoading,
  },
});

// eslint-disable-next-line max-len
export const SET_FORM_SUCCESS_SD_CONSUMER_CALL_BACK = '[forms] set success sd consumer call back';
export type SetFormSuccess = {
  type: typeof SET_FORM_SUCCESS_SD_CONSUMER_CALL_BACK;
  payload: {
    isSuccess: boolean;
  }
};

export const setFormSuccess = (
  isSuccess: boolean,
): SetFormSuccess => ({
  type: SET_FORM_SUCCESS_SD_CONSUMER_CALL_BACK,
  payload: {
    isSuccess,
  },
});

export const RESET_FORM_SD_CONSUMER_CALL_BACK = '[sd-consumer] form reset';
export type ResetForm = {
  type: typeof RESET_FORM_SD_CONSUMER_CALL_BACK,
};

export const resetForm: ResetForm = {
  type: RESET_FORM_SD_CONSUMER_CALL_BACK,
};

// eslint-disable-next-line max-len
export const SET_INPUT_SD_CONSUMER_CALL_BACK = '[forms] input change sd consumer call back';

export type SetInput = {
  type: typeof SET_INPUT_SD_CONSUMER_CALL_BACK;
  payload: {
    fieldName: string;
    value: string;
    validators: Validator[];
  }
}
export type SetInputValidated = {
  type: typeof SET_INPUT_SD_CONSUMER_CALL_BACK;
  payload: {
    [fieldName: string]: {
      value: string;
      error: string;
    }
  }
}

export const setRequiredInput = (fieldName: string) => (
  value: string,
): SetInput => ({
  type: SET_INPUT_SD_CONSUMER_CALL_BACK,
  payload: {
    fieldName,
    value,
    validators: [validateRequired],
  },
});

// eslint-disable-next-line max-len
export const SET_PHONE_INPUT_SD_CONSUMER_CALL_BACK = '[forms] phone number change sd consumer call back';
export type SetPhoneInput = {
  type: typeof SET_PHONE_INPUT_SD_CONSUMER_CALL_BACK;
  payload: {
    countryCode: string;
    number: string;
    isRequired: boolean;
  }
}
export type SetPhoneInputValidated = {
  type: typeof SET_PHONE_INPUT_SD_CONSUMER_CALL_BACK;
  payload: {
    phoneNumber: {
      countryCode: string;
      number: string;
      error: string;
    };
  }
}

export const setRequiredPhoneInput = (
  countryCode: string,
  number: string,
): SetPhoneInput => ({
  type: SET_PHONE_INPUT_SD_CONSUMER_CALL_BACK,
  payload: {
    countryCode,
    number,
    isRequired: true,
  },
});

// eslint-disable-next-line max-len
export const SET_GENERAL_ERROR_SD_CONSUMER_CALL_BACK = 'set general error sd consumer callback';
export type SetGeneralErrorMessage = {
  type: typeof SET_GENERAL_ERROR_SD_CONSUMER_CALL_BACK;
  payload: string;
}

export const setGeneralErrorMessage = (
  message: SetGeneralErrorMessage['payload'],
): SetGeneralErrorMessage => ({
  type: SET_GENERAL_ERROR_SD_CONSUMER_CALL_BACK,
  payload: message,
});

export type SdConsumerCallbackActions =
| SetInputValidated
| SetPhoneInputValidated
| SetFormLoading
| SetFormSuccess
| SetGeneralErrorMessage
| ResetForm;
